import React from "react"
import { Lock } from "react-feather"

import SEO from "../components/SEO"
import Navbar from "../components/Navbar"

const BankDetails = ({ pageContext: { pageName } }) => {
  return (
    <>
      <Navbar />
      <SEO
        title="Bank details"
        description="Please double check the bank details before making any transfer."
        pageName={pageName}
        noIndex
      />
      <section className="container content d-flex pt-0">
        <div className="row justify-content-center">
          <div className="col col-md-8 col-lg-6 text-center">
            <p className="bg-light border py-1 px-3 rounded-pill d-flex align-items-center">
              <Lock className="text-success me-3" />
              https://www.evobend.com/bank-details/
            </p>

            <p className="alert alert-danger">
              Please make sure that the URL in your browser is the same as shown
              above. Also verify that your browser does not have any Add-ons
              installed (use incognito mode) and that you are in an secure
              network (use mobile service). Double check on a second device.
              Ideally use a mobile phone.
            </p>

            <p>
              Bank: Sparkasse Berchtesgadener Land
              <br />
              IBAN: DE73 7105 0000 0020 5072 57
              <br />
              BIC: BYLADEM1BGL
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default BankDetails
